.AppContainer {
  min-height: 100vh;

  transition: background-color ease-in-out 0.3s;
}
.AppFooter {
  position: fixed;
  width: auto;
  bottom: 0;
  right: 6px;
  margin-bottom: 6px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .uk-label {
    button {
      vertical-align: top;
    }
    span { 
      margin: 0;
      vertical-align: middle;

      pointer-events: none;
      user-select: none; 
    }
  }
}

//fix for color propagation due to UIkit themes
.uk-color-prop-fix {
  color: inherit !important;
  &:hover {
    color: inherit !important;
  }
}
@import './theme.scss';
@import '../node_modules/uikit/src/scss/variables-theme.scss';
@import '../node_modules/uikit/src/scss/mixins-theme.scss';
@import '../node_modules/uikit/src/scss/uikit-theme.scss';

body {
  margin: 0;
  // font set by uikit theme
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//fix for color propagation on alerts
.uk-alert-close-fix {
  color: inherit !important;
}
.uk-alert-close-fix:hover {
  color: inherit !important;
}
//fix modal z-index for layering
.uk-modal {
  z-index: 101;
}
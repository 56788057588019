
.ais-SearchBox {
    input {
        width: 85%;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 0;
        border-width: 1px;
    }

    button {
        height: 40px;
        padding: 0 10px;
        border-radius: 0;
        border-width: 1px;
    }
}
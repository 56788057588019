
//custom uikit-compatible theme
//color palette
$palette-light: #F0EEEB;
$palette-light-accent: #8E8E96;
$palette-main-accent: #D84B37;
$palette-dark-accent: #AF8868;
$palette-dark: #302F42;
//uikit/material specific
$palette-success: #6bad74;
$palette-warning: #D5973E;
$palette-danger: #E6375A;

//uikit overrides
//color
$global-color: $palette-dark;
$global-border: lighten($palette-light-accent, 27.5%);
$global-muted-color: mix($palette-light-accent, $palette-dark, 30%);
$global-emphasis-color: $palette-dark;
$global-inverse-color: $palette-light;
$global-link-color: $palette-light-accent;
$global-link-hover-color: lighten($palette-light-accent, 10%);
//background
$global-background: lighten($palette-light, 5%);
$global-muted-background: $palette-light;
$global-secondary-background: $palette-dark;
$global-primary-background: $palette-main-accent;
$global-success-background: $palette-success;
$global-warning-background: $palette-warning;
$global-danger-background: $palette-danger;
$base-selection-background: $palette-light-accent;
$base-ins-background: #ffd;
$base-mark-background: #ffd;
$section-primary-background: $palette-main-accent;
$section-primary-color-mode: light;
$section-secondary-background: $global-muted-color;
$section-secondary-color-mode: light;
//font
@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./resources/fonts/Raleway-Regular.ttf) format('truetype');
}
$global-font-size: 18px;
$global-font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;